import Home from './pages/home/Home';
import './App.scss';
import Watch from './pages/Watch/Watch';
import Register from './pages/Register/Register';
import Login from './pages/Login/Login';
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { useContext } from 'react';
import { AuthContext } from './authContext/AuthContext'


const App = () => {
  const {user} = useContext(AuthContext);
  return (  
    <>
      <Router>
        <Routes>
          <Route path = "/" element={user? <Home type="" /> : <Navigate to="/register" />} />                    
          <Route path = "/register" element={!user? <Register /> : <Navigate to="/" />} />                    
          <Route path = "/login" element={!user? <Login /> : <Navigate to="/" />} />
          { user && ( 
            <>
              <Route path="/movies" element={<Home type="movie" />} />
              <Route path="/series" element={<Home type="series" />} />
              <Route path="/watch" element={ <Watch /> } />
            </>
           )}
        </Routes>
      </Router>
    </>       
  );
}

export default App;
