import axios from 'axios';
import React, { useRef, useState } from 'react';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import './Register.scss';

function Register() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [username, setUsername] = useState("");
  const naviagte = useNavigate();


  const emailRef = useRef();
  const passwordRef = useRef();
  const usernameRef = useRef();

  const handleClick = () =>{
    console.log("hehe");
    naviagte("/login");
  }
  const handleStart = () => {
    setEmail(emailRef.current.value);
  };
  const handleFinish = async (e) => {
    e.preventDefault();
    // setPassword(passwordRef.current.value);
    // setUsername(usernameRef.current.value);
    try {
      console.log(email)
      console.log(username)
      console.log(password)
      const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}auth/register`, { email:email, username:username, password:password });
      console.log(res);
      naviagte("/login");
    } catch (err) {
      console.log("error in register ", err);
    }
  };

  return (
    <div className='register'>
      <div className="top">
        <div className="wrapper">
          <img
            className="logo"
            src="https://upload.wikimedia.org/wikipedia/commons/thumb/0/08/Netflix_2015_logo.svg/2560px-Netflix_2015_logo.svg.png"
            alt=""
          />
          <div onClick={handleClick} className="loginButton">
          <NavLink to="/login" className='link'>
            Sign In
          </NavLink>
          </div>
        </div>
      </div>
      <div className="container">
        <h1>Unlimited movies, TV shows, and more.</h1>
        <h2>Watch anywhere. Cancel anytime.</h2>
        <p>
          Ready to watch? Enter your email to create or restart your membership.
        </p>
        {!email ? (
          <div className="input">
            <input type="email" placeholder="email address" ref={emailRef} />
            <button className="registerButton" onClick={handleStart}>
              Get Started
            </button>
          </div>
        ) : (
          <form className="input">
            <input type="username" placeholder="username" onChange={(e)=>setUsername(e.target.value)} />
            <input type="password" placeholder="password" onChange={(e)=>setPassword(e.target.value)} />
            <button className="registerButton" onClick={handleFinish}>
              Start
            </button>
          </form>
        )}
      </div>
    </div>
  );
}

export default Register