import React from 'react'
import Featured from '../../components/Featured';
import { List } from '../../components/List';
import { Navbar } from '../../components/Navbar';
import './home.scss';
import axios from 'axios';
import { useState, useEffect } from 'react';

const Home = ({ type }) => {
  const [lists, setLists] = useState([]);
  const [genre, setGenre] = useState("");
  const [noMatch, setNoMatch] = useState(false);

  useEffect(() => {
    const getRandomLists = async () => {
      setNoMatch(false);
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}lists${type ? "?type=" + type : ""}${genre && type!=="" ? "&genre=" + genre : ""
          }`,
          {
            headers: {
              token: "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
            },
          }
        );
        if(genre && type==="")setGenre("");
        // console.log(res.data);
        if(res.data.length===0)setNoMatch(true);
        setLists(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    getRandomLists();
  }, [type, genre,]);

  return (
    <div className='home'>
      <Navbar />
      <Featured type={type} setGenre={setGenre} />
      <div style={{}}>
        {lists.map((list) => (
          <List list={list} key={list._id} />
        ))}
      </div>
      {noMatch?<h1 style={{"height":"26vh","color":"white","width":"100%", "marginTop":"50px", "textAlign":"center"}}>No match found !</h1>:<></>}
    </div>
  )
}

export default Home