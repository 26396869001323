import React, { useContext, useState } from 'react'
import { Search, Notifications, ArrowDropDown } from '@mui/icons-material';
import './navbar.scss';
import { NavLink } from 'react-router-dom';
import { AuthContext } from '../authContext/AuthContext';
import { logout } from "../authContext/AuthActions"

export const Navbar = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const { dispatch } = useContext(AuthContext);
  const person = JSON.parse(localStorage.getItem("user")).username;

  window.onscroll = () => {
    setIsScrolled(window.pageYOffset === 0 ? false : true);
    return () => (window.onscroll = null);
  };
  // console.log(isScrolled);
  return (
    <div className={isScrolled ? "navbar scrolled" : 'navbar'}>
      <div className="container">
        <div className="left">
          <img
            src="https://upload.wikimedia.org/wikipedia/commons/thumb/0/08/Netflix_2015_logo.svg/2560px-Netflix_2015_logo.svg.png"
            alt=""
          />
          <NavLink to="/" className='link'>
            <span>Homepage</span>
          </NavLink>
          <NavLink to="/series" className='link'>
            <span className='mainNavLink navLink'>Series</span>
          </NavLink>
          <NavLink to="/movies" className='link'>
            <span className='mainNavLink navLink'>Movies</span>
          </NavLink>
          {/* <span className='navLink'>New and Popular</span> */}
          {/* <span className='navLink'>My List</span> */}
        </div>
        <div className="right">
          <Search className="icon" />
          <span>{person}</span>
          <Notifications className="icon" />
          <div className="img">
            <span className='user'>{person.slice(0, 1).toUpperCase()}</span>
          </div>
          <div className="profile">
            <ArrowDropDown className="icon" />
            <div className="options">
              <span>Settings</span>
              <NavLink to='/register' className='link'><span onClick={() => dispatch(logout())} >Logout</span></NavLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
