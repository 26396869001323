import { InfoOutlined, PlayArrow } from '@mui/icons-material';
import React from 'react'
import { useEffect, useState } from 'react';
import axios from "axios";
import './Featured.scss';
import { Link } from 'react-router-dom';

export default function Featured({ type , setGenre}) {
  const [content, setContent] = useState({});

  useEffect(() => {
    setContent({});
    const getRandomContent = async () => {
      try {
        const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}movies/random?type=${type}`, {
          headers: {
            token: "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
          },
        });
        setContent(res.data[0]);
      } catch (err) {
        console.log(err);
      }
    };
    getRandomContent();
  }, [type, setGenre]);

  // console.log(content);
    return (
      <div className="featured">
      {type && (
        <div className="category">
          <span>{type === "movie" ? "Movies" : "Series"}</span>
          <select
            name="genre"
            id="genre"
            onChange={(e) => setGenre(e.target.value)}
          >
            <option>Genre</option>
            <option value="Adventure">Adventure</option>
            <option value="Comedy">Comedy</option>
            <option value="Crime">Crime</option>
            <option value="Action">Action</option>
            <option value="Horror">Horror</option>
            <option value="Romance">Romance</option>
            <option value="Sci-fi">Sci-fi</option>
            <option value="Thriller">Thriller</option>
            <option value="Animation">Animation</option>
          </select>
        </div>
      )}
      <img src={content.img} alt="" />
      <div className="info">
        <img src={content.imgSm} alt="" />
        <span className="desc">{content.desc?.slice(0,160)}...</span>
        <div className="buttons">
        <Link to="/watch" className='link' state = {{movie: content} }>
          <button className="play">
            <PlayArrow />
            <span>Play</span>
          </button>
          </Link>
          <button className="more">
            <InfoOutlined />
            <span>Info</span>
          </button>
        </div>
      </div>
    </div>
    );
  }