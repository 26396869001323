import { ArrowBackOutlined } from '@mui/icons-material'
import React from 'react';
import { useLocation, Link } from 'react-router-dom';
import './Watch.scss';

function Watch() {
  const location = useLocation();
  const movie = location.state.movie;
  // console.log(location)
  // console.log(movie);
  return (
    <div className="watch">
      <Link to="/">
        <div className="back">
          <ArrowBackOutlined />
          Home
        </div>
      </Link>
      <video className="video" autoPlay progress controls src={movie.trailer} />
    </div>
  );
}


export default Watch